import React, { FC, ImgHTMLAttributes } from 'react'

import { cx } from '@linaria/core'
import { styled } from '@linaria/react'

import { WithClassName } from 'common/types'

export const BaseImage: FC<
  WithClassName &
    ImgHTMLAttributes<HTMLImageElement> & {
      imageSource: string
    }
> = ({ className, imageSource, ...props }) => {
  return <Parent className={cx(className)} src={imageSource} {...props} />
}

const Parent = styled.img`
  vertical-align: top;
`
